import React from "react";
import Footer from "../GeneralUse/Footer";
import Header from "../GeneralUse/Header";
import Define from "./Define/Define";
import FutureComponent from "./FutureComponent";
import Happen from "./Happen/Happen";
import Hero from "./Hero/Hero";
import Popular from "./Popular";
import SpotlightComponent from "./SpotlightComponent";

const LandingPage = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Define />
      <Happen />
      <FutureComponent />
      <SpotlightComponent />
      <Popular />
      <Footer />
    </div>
  );
};

export default LandingPage;
