import React from "react";
import styled from "styled-components";
import Definebox from "../Allprops/Definebox";
import space from "../Assets/space.svg";
import img1 from "../Assets/spaner.svg";
import img2 from "../Assets/api.svg";
import img3 from "../Assets/workspace.svg";
import img4 from "../Assets/governance.svg";

const Define = () => {
  return (
    <Container>
      <Wrapper1>
        <Left>
          <Right>
            <h2>What is Postman?</h2>
            <span>
              Postman is an API platform for building and using APIs. Postman
              simplifies each step of <br /> the API lifecycle and streamlines
              collaboration so you can create better APIs—faster.
            </span>
          </Right>
          <Space src={space} />
        </Left>
      </Wrapper1>
      <Wrapper2>
        <Definebox
          image={img1}
          span="API Tools"
          p="A comprehensive set of tools that help accelerate the API Lifecycle -
        from design, testing, documentation, and mocking to discovery."
        />
        <Definebox
          image={img2}
          span="API Respository"
          p="Easily store, iterate and collaborate around all your API artifacts on one central platform used across teams."
        />
        <Definebox
          image={img3}
          span="Workspaces"
          p="Organize your API work and collaborate with teammates across your organization or stakeholders across the world."
        />
        <Definebox
          image={img4}
          span="Governace"
          p="Improve the quality of APIs with governance rules that ensure APIs are designed, built, tested, and distributed meeting organizational standards."
        />
        <Wrapmedia>
          <Button>Learn more</Button>
        </Wrapmedia>
      </Wrapper2>
      <Wrap>
        <Button>Learn more</Button>
      </Wrap>
    </Container>
  );
};

export default Define;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 320px) {
    display: none;
  }
`;
const Wrapmedia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  @media screen and (max-width: 320px) {
    display: block;
    align-self: center;
    margin-top: 20px;
  }
`;

const Button = styled.div`
  padding: 5px 15px;
  border: 1px solid rgb(0, 0, 0, 0.1);
  margin-right: 10px;
  border-radius: 4px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  color: black;
  transition: all 360ms;
  cursor: pointer;
  :hover {
    border: 1px solid rgb(0, 0, 0, 0.3);
  }
`;

const Wrapper2 = styled.div`
  width: 95%;
  margin-left: 18px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;

  @media screen and (max-width: 320px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Right = styled.div`
  margin-left: 50px;
  span {
    font-weight: 500;
    font-size: 18px;
    color: rgb(0, 0, 0, 0.6);
    @media screen and (max-width: 320px) {
      font-size: 12px;
      width: 100px;
    }
  }
  @media screen and (max-width: 320px) {
    margin-right: 30px;
  }
`;

const Wrapper1 = styled.div`
  width: 100%;
`;

const Left = styled.div`
  display: flex;
`;

const Space = styled.img`
  height: 210px;
  position: absolute;
  top: -32%;
  right: 7%;
  align-self: flex-end;
  @media screen and (max-width: 320px) {
    display: none;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 500px;

  background-color: #f9f9f9;
  position: relative;
`;
